
import universal, { setHasBabelPlugin } from '/opt/build/repo/node_modules/react-static/node_modules/react-universal-component/dist/index.js'


setHasBabelPlugin()

const universalOptions = {
  loading: () => null,
  error: props => {
    console.error(props.error);
    return <div>An error occurred loading this page's template. More information is available in the console.</div>;
  },
}

const t_0 = universal(import('../src/pages/404.js'), universalOptions)
const t_1 = universal(import('../src/pages/communities/atlantique.js'), universalOptions)
const t_2 = universal(import('../src/pages/communities/cherry-grove.js'), universalOptions)
const t_3 = universal(import('../src/pages/communities/dunewood.js'), universalOptions)
const t_4 = universal(import('../src/pages/communities/fair-harbor.js'), universalOptions)
const t_5 = universal(import('../src/pages/communities/fi-lighthouse.js'), universalOptions)
const t_6 = universal(import('../src/pages/communities/fi-pines.js'), universalOptions)
const t_7 = universal(import('../src/pages/communities/index.js'), universalOptions)
const t_8 = universal(import('../src/pages/communities/kismet.js'), universalOptions)
const t_9 = universal(import('../src/pages/communities/ocean-bay-park.js'), universalOptions)
const t_10 = universal(import('../src/pages/communities/ocean-beach.js'), universalOptions)
const t_11 = universal(import('../src/pages/communities/point-o-woods.js'), universalOptions)
const t_12 = universal(import('../src/pages/communities/sailors-haven-sunken-forest.js'), universalOptions)
const t_13 = universal(import('../src/pages/communities/saltaire.js'), universalOptions)
const t_14 = universal(import('../src/pages/communities/seaview.js'), universalOptions)
const t_15 = universal(import('../src/pages/communities/water-island.js'), universalOptions)
const t_16 = universal(import('../src/pages/directory.js'), universalOptions)
const t_17 = universal(import('../src/pages/index.js'), universalOptions)
const t_18 = universal(import('../src/pages/info/charters.js'), universalOptions)
const t_19 = universal(import('../src/pages/info/contact.js'), universalOptions)
const t_20 = universal(import('../src/pages/info/faq.js'), universalOptions)
const t_21 = universal(import('../src/pages/info/fares.js'), universalOptions)
const t_22 = universal(import('../src/pages/info/freight/faq.js'), universalOptions)
const t_23 = universal(import('../src/pages/info/freight/hours.js'), universalOptions)
const t_24 = universal(import('../src/pages/info/freight/index.js'), universalOptions)
const t_25 = universal(import('../src/pages/info/freight/ldw.js'), universalOptions)
const t_26 = universal(import('../src/pages/info/parking.js'), universalOptions)
const t_27 = universal(import('../src/pages/info/passenger-freight.js'), universalOptions)
const t_28 = universal(import('../src/pages/info/planning-your-trip.js'), universalOptions)
const t_29 = universal(import('../src/pages/info/privacy.js'), universalOptions)
const t_30 = universal(import('../src/pages/schedule-board.js'), universalOptions)
const t_31 = universal(import('../src/pages/schedules/all.js'), universalOptions)
const t_32 = universal(import('../src/pages/schedules/atlantique.js'), universalOptions)
const t_33 = universal(import('../src/pages/schedules/dunewood.js'), universalOptions)
const t_34 = universal(import('../src/pages/schedules/fair-harbor.js'), universalOptions)
const t_35 = universal(import('../src/pages/schedules/index.js'), universalOptions)
const t_36 = universal(import('../src/pages/schedules/kismet.js'), universalOptions)
const t_37 = universal(import('../src/pages/schedules/ocean-bay-park.js'), universalOptions)
const t_38 = universal(import('../src/pages/schedules/ocean-beach.js'), universalOptions)
const t_39 = universal(import('../src/pages/schedules/saltaire.js'), universalOptions)
const t_40 = universal(import('../src/pages/schedules/seaview.js'), universalOptions)
const t_41 = universal(import('../src/pages/updates.js'), universalOptions)


// Template Map
export default {
  '../src/pages/404.js': t_0,
'../src/pages/communities/atlantique.js': t_1,
'../src/pages/communities/cherry-grove.js': t_2,
'../src/pages/communities/dunewood.js': t_3,
'../src/pages/communities/fair-harbor.js': t_4,
'../src/pages/communities/fi-lighthouse.js': t_5,
'../src/pages/communities/fi-pines.js': t_6,
'../src/pages/communities/index.js': t_7,
'../src/pages/communities/kismet.js': t_8,
'../src/pages/communities/ocean-bay-park.js': t_9,
'../src/pages/communities/ocean-beach.js': t_10,
'../src/pages/communities/point-o-woods.js': t_11,
'../src/pages/communities/sailors-haven-sunken-forest.js': t_12,
'../src/pages/communities/saltaire.js': t_13,
'../src/pages/communities/seaview.js': t_14,
'../src/pages/communities/water-island.js': t_15,
'../src/pages/directory.js': t_16,
'../src/pages/index.js': t_17,
'../src/pages/info/charters.js': t_18,
'../src/pages/info/contact.js': t_19,
'../src/pages/info/faq.js': t_20,
'../src/pages/info/fares.js': t_21,
'../src/pages/info/freight/faq.js': t_22,
'../src/pages/info/freight/hours.js': t_23,
'../src/pages/info/freight/index.js': t_24,
'../src/pages/info/freight/ldw.js': t_25,
'../src/pages/info/parking.js': t_26,
'../src/pages/info/passenger-freight.js': t_27,
'../src/pages/info/planning-your-trip.js': t_28,
'../src/pages/info/privacy.js': t_29,
'../src/pages/schedule-board.js': t_30,
'../src/pages/schedules/all.js': t_31,
'../src/pages/schedules/atlantique.js': t_32,
'../src/pages/schedules/dunewood.js': t_33,
'../src/pages/schedules/fair-harbor.js': t_34,
'../src/pages/schedules/index.js': t_35,
'../src/pages/schedules/kismet.js': t_36,
'../src/pages/schedules/ocean-bay-park.js': t_37,
'../src/pages/schedules/ocean-beach.js': t_38,
'../src/pages/schedules/saltaire.js': t_39,
'../src/pages/schedules/seaview.js': t_40,
'../src/pages/updates.js': t_41
}

export const notFoundTemplate = "../src/pages/404.js"
